import { render, staticRenderFns } from "./DocumentosEmpresa.vue?vue&type=template&id=5f203abe&"
import script from "./DocumentosEmpresa.vue?vue&type=script&lang=js&"
export * from "./DocumentosEmpresa.vue?vue&type=script&lang=js&"
import style0 from "./DocumentosEmpresa.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VCol,VContainer,VIcon,VRow,VTreeview})
