<template>
  <v-container>
    <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
      Documentos de empresa
    </div>

    <v-row>
      <v-col cols="auto">
        <v-treeview
          class="ml-2"
          :items="infs"
          item-key="InfCatId"
          item-text="InfCatNom"
          item-children="InfCatChildren"
          open-on-click
          transition
        >
          <template v-slot:prepend="{ item, open }">
            <v-icon color="orange" large v-if="item.FileType == null">
              {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
            </v-icon>
            <v-icon :color="filesColor[item.FileType]" large v-else-if="files[item.FileType] != null">
              {{ files[item.FileType] }}
            </v-icon>
            <v-icon large v-else>
              {{ files["unknown_ext"] }}
            </v-icon>
          </template>

          <template v-slot:append="{ item }">
            <a @click="viewItemClick(item)" v-if="item.InfCatObs == 'true'">
              <v-icon color="blue-grey" large class="ml-2" v-if="item.FileType != null" >
                mdi-download-circle
              </v-icon>
            </a>
            <a @click="dowloadItemClick(item)" v-else>
              <v-icon color="blue-grey" large class="ml-2" v-if="item.FileType != null" >
                mdi-download-circle
              </v-icon>
            </a>
          </template>

          <template slot="label" slot-scope="{ item }">
            <a class="black--text text-body-2" @click="clickEvent(item)">{{ item.InfCatNom }}</a>
          </template>

        </v-treeview>
      </v-col>
    </v-row>

    <!-- <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="infs"
          :footer-props="{itemsPerPageOptions: [15, 30, 50, 100]}"
          :options.sync="options"
          :server-items-length="totalInfs"
          :loading="loading"
          dense
          style="overflow: scroll"
          mobile-breakpoint="0"
          class="elevation-1"
          @click:row="seleccion"
          >

          <template v-slot:item.actions="{ item }">
            <v-icon
              medium
              class="mr-2"
              @click="downloadFile(item)"
            >
              mdi-download-circle
            </v-icon>
          </template>

        </v-data-table>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  computed:{
      ...mapState(['empId', 'perId', 'perIdAux', 'esTienda', 'urlRaiz'])
  },
  data () {
    return {
      totalInfs: 0,
      infs: [],
      loading: true,
      options: {itemsPerPage: 50},
      headers: [
        { text: 'Documento', sortable: false, value: 'InfNom' },
        { text: 'Acción', value: 'actions', sortable: false }
      ],
      files: {
        html: 'mdi-language-html5',
        js: 'mdi-nodejs',
        json: 'mdi-code-json',
        md: 'mdi-language-markdown',
        pdf: 'mdi-file-pdf',
        png: 'mdi-file-image',
        txt: 'mdi-file-document-outline',
        xls: 'mdi-file-excel',
        xlsx: 'mdi-file-excel',
        mp4: 'mdi-file-video',
        jpg: 'mdi-file-image',
        unknown_ext: 'mdi-file'
      },
      filesColor: {
        html: 'blue',
        js: 'yellow',
        json: 'orange',
        md: 'grey',
        pdf: 'red',
        png: 'indigo',
        txt: 'blue',
        xls: 'green',
        xlsx: 'green',
        mp4: 'blue',
        jpg: 'indigo',
        unknown_ext: 'grey'
      },

    }
  },
  watch: {
    options: {
      handler () {
        this.getDataFromApi()
          .then(data => {
            this.Infs = data.items
            this.totalInfs =  data.total
          })
      },
      deep: true,
    },
  },
  mounted () {
    this.getDataFromApi()
      .then(data => {
        this.infs = data.items
        this.totalInfs = data.total
      })
  },
  methods: {
    downloadFile(itm){ 
        var descarga = document.createElement("a");
        // descarga.href = this.urlRaiz + "/InfFiles/" + itm.InfFic ;
        descarga.href = this.urlRaiz+ itm.UrlRelativePath ;

        var CarpetaInf = this.$store.state.emp.DirDocs + "/";
        var CarpetaRepositorio = "RepositorioDocumentos" + "/" + this.$store.state.emp.DirDocs + "/";
        if (this.$store.state.esEmpleado) {
            CarpetaInf += "Externa";
            CarpetaRepositorio += "Externa";
        }
        else {
            CarpetaInf += "Interna";
            CarpetaRepositorio += "Interna";
        }
        // descarga.href = this.urlRaiz + itm.UrlRelativePath.replace('Rincon/Interna', 'RepositorioDocumentos/Rincon/Interna') ;
        descarga.href = this.urlRaiz + itm.UrlRelativePath.replace(CarpetaInf, CarpetaRepositorio) ;
        descarga.target = "_blank";
        descarga.download = itm.InfFic;
        descarga.style.display="none";
        var clickEvent = new MouseEvent('click', { 'view': window, 'bubbles': true, 'cancelable': true });
        descarga.dispatchEvent(clickEvent);

        // this.entidadActualSet(itm);
        // this.receptorPerIdSet(this.perId);
        // this.$router.push('/recibir-mensajes-edicion')
    },
    viewFile(){
      this.$router.push('/visor-documentos')
    },
    // clickEvent(itm){

    // },
    getDataFromApi () {
      this.loading = true
      return new Promise((resolve) => {
        // const { sortBy, sortDesc } = this.options

        var NumRegsPag = this.options.itemsPerPage;
        var NumPag = this.options.page;
        
        var controllerParameters = {
                Action: "GET_DIRECTORIES_LIST_PAGINATED",
                NumRegsPag: NumRegsPag,
                NumPag: NumPag,
                EsLocal: this.$store.state.local,
                EsDesarrollo: this.$store.state.desarrollo,
                emp: this.$store.state.emp,
                esTienda: this.$store.state.esTienda,
                esDepartamento: this.$store.state.esDepartamento,
                esEmpleado: this.$store.state.esEmpleado
            } ;       

        var AuthToken = localStorage.getItem('token');
        axios({ method: "POST", "url": this.urlRaiz + "/api/inf", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } }).then(result => {

          let items = result.data.EntsList;
          const total = result.data.EntsTotCount;

          // if (sortBy.length === 1 && sortDesc.length === 1) {
          //   items = items.sort((a, b) => {
          //     const sortA = a[sortBy[0]]
          //     const sortB = b[sortBy[0]]

          //     if (sortDesc[0]) {
          //       if (sortA < sortB) return 1
          //       if (sortA > sortB) return -1
          //       return 0
          //     } else {
          //       if (sortA < sortB) return -1
          //       if (sortA > sortB) return 1
          //       return 0
          //     }
          //   })
          // }

          setTimeout(() => {
            this.loading = false
            resolve({
              items,
              total,
            })
          }, 1000)
        });
      })
    },
    getCloudFileFromApi(file) {
      return new Promise((resolve) => {
        const controllerParameters = {
          Action: 'GET_CLOUD_ITEM',
          FileName: file.UrlRelativePath
        };
        const AuthToken = localStorage.getItem('token');
        axios({ method: "POST", "url": this.urlRaiz + "/api/inf", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } }).then(result => {
          const item = result.data;

          setTimeout(() => {
              resolve({
                item
              })
          }, 1000)
        });
      })
    },
    postInfVisToApi(fileName, ambito, folderNames) {
      return new Promise((resolve) => {
        const controllerParameters = {
          Action: 'SAVE_INFVIS',
          EsTienda: this.esTienda,
          InfFic: fileName,
          PerId: this.perId,
          PerIdAux: this.perIdAux,
          InfAmbito: ambito,
          FolderNames: folderNames
        };
        const AuthToken = localStorage.getItem('token');
        axios({ method: "POST", "url": this.urlRaiz + "/api/inf", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } }).then(result => {
          const success = result.data;

          setTimeout(() => {
              resolve({
              success
            })
          }, 1000)
        });
      })
    },
    saveInfVis(item) {
      const split = item.UrlRelativePath.split('/');
      const indexAmbito = split.findIndex(f => f.toUpperCase() == 'EXTERNA' || f.toUpperCase() == 'INTERNA')
      const ambito = split[indexAmbito];
      const folderNames = split.slice(indexAmbito + 1, split.length - 1);

      console.log(ambito);
      console.log(folderNames);
      // this.postInfVisToApi(item.InfCatNom, ambito, folderNames);
    },
    downloadCloudFile(item)
    {
      this.getCloudFileFromApi(item)
        .then(data => {
            if(data.item == null) { 
              alert('Documento no encontrado');
              return; 
            }
            this.descargarDocumento(data.item.File, data.item.FileName, data.item.Mime);
        });
    },
    viewCloudFile(item)
    {
      this.getCloudFileFromApi(item)
        .then(data => {
            if(data.item == null) { 
              alert('Documento no encontrado');
              return; 
            }
            this.verDocumento(data.item.File, data.item.FileName, data.item.Mime);
        });
    },
    descargarDocumento(doc, docName, contentType = 'application/octet-stream'){
        this.downloading = true;

        // Datos del fichero
        // const contentType = 'application/octet-stream';
        
        // Covertir de base 64 a Blob
        const byteCharacters = atob(doc);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: contentType});

        // Descarga del fichero
        const url = window.URL.createObjectURL(blob);
        var a = document.createElement('a'); 
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = docName; // Nombre del fichero
        a.click();
        a.remove();

        this.downloading = false;
    },
    verDocumento(doc, docName, contentType = 'application/octet-stream'){
        this.downloading = true;

        // Datos del fichero
        // const contentType = 'application/octet-stream';
        
        // Covertir de base 64 a Blob
        const byteCharacters = atob(doc);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: contentType});

        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');

        this.downloading = false;
    },
    dowloadItemClick (item) {
      console.log(item);
      this.saveInfVis(item);
      if(item.InfCatObs == "true")
      {
        this.downloadCloudFile(item);
      }
      else
      {
        this.downloadFile(item);
      }
    },
    viewItemClick (item) {
      console.log(item);
      this.saveInfVis(item);
      if(item.InfCatObs == "true")
      {
        const routeData = this.$router.resolve({name: 'file', params: {file: btoa(item.UrlRelativePath)}});
        window.open(routeData.href, '_blank');
        // this.viewCloudFile(item);
      }
    }
  },
}
</script>
<style>
  .col_para {
    min-width: 250px;
  }
</style>